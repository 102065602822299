export const ATLASSIAN_INTELLIGENCE_INFO_LINK =
	'https://www.atlassian.com/trust/atlassian-intelligence';
export const ATLASSIAN_INTELLIGENCE_SEARCH_INFO_LINK =
	'https://support.atlassian.com/confluence-cloud/docs/use-atlassian-intelligence-to-search-for-answers/';
export const ATLASSIAN_INTELLIGENCE_LINK =
	'https://www.atlassian.com/software/artificial-intelligence';
export const ATLASSIAN_INTELLIGENCE_TRUST_LINK =
	'https://www.atlassian.com/trust/atlassian-intelligence';
export const ATLASSIAN_ACCEPTABLE_USE_POLICY_LINK =
	'https://www.atlassian.com/legal/acceptable-use-policy';
export const ATLASSIAN_AI_USAGE_LIMITS_LINK =
	'https://support.atlassian.com/organization-administration/docs/usage-limits-in-atlassian-intelligence/';
export const ATLASSIAN_AI_TROUBLESHOOT_LINK =
	'https://support.atlassian.com/organization-administration/docs/troubleshooting-for-atlassian-intelligence/';
export const ATLASSIAN_ROVO_LINK = 'https://www.atlassian.com/software/rovo';
export const FEEDBACK_ENTRYPOINT_ID_SAIN = '82633dea-7fbf-4faa-9a51-a83a3fd181d9';
export const FEEDBACK_ENTRYPOINT_ID_READING_AIDS = '365 5ce1d-16b8-4630-9e93-6c39069625fa';
export const FEEDBACK_ENTRYPOINT_ID_TOPICS = '10f4daa4-bcfc-4d42-ae33-92888648d9db';
export const FEEDBACK_CONTEXT_CF = 'customfield_10047';

//analytics
export const DEFAULT_GAS_CHANNEL = 'fabric-elements';

// known sources / consumers of dialog
export enum SOURCE {
	SEARCH_DIALOG = 'searchDialog',
	ADVANCED_SEARCH = 'advancedSearch',
	READING_AIDS = 'readingAids',
}

export const READING_AIDS_DIALOG_WIDTH = '500px';

// SAIN streaming API
export const SAIN_STREAMING_API = '/gateway/api/smartAnswers/stream';

// Assistance service API
export const ASSISTANCE_SERVICE_API_BASE_URL = '/gateway/api/assist';

// AGG
export const DEFAULT_GRAPHQL_ENDPOINT = '/gateway/api/graphql';

// Possible responses from the smart answer intent detection api, used in FPS for determining where to rendering the smart answer
export enum QueryIntent {
	DEFAULT = 'DEFAULT', // No query (no smart answer render)
	NATURAL_LANGUAGE_QUERY = 'NATURAL_LANGUAGE_QUERY', // Returned when SAIN detects a natural language query (renders above the search results)
	KEYWORD_OR_ACRONYM = 'KEYWORD_OR_ACRONYM', // Returned when SAIN detects a keyword or acronym (renders on the right rail panel)
	PERSON = 'PERSON', // Returned when SAIN detects a request for a person (renders above the search results)
	TEAM = 'TEAM', // Returned when SAIN detects a request for a team (renders above the search results)
	NONE = 'NONE', // Used when there is a query but no intent is detected (no smart answer render)
}

export enum CardTemplate {
	DEFAULT = 'DEFAULT',
	SMART_ANSWER = 'SMART_ANSWER',
	RIGHT_PANEL = 'RIGHT_PANEL',
}

export enum AiBrand {
	ROVO = 'ROVO',
	AI = 'ATLASSIAN INTELLIGENCE',
}
