import React from 'react';
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import { N50A, N60A } from '@atlaskit/theme/colors';
import { dialogWidth, dialogPageTakeoverWidth, grid } from '../style-utils';
import { gridSize } from '@atlaskit/theme/constants';
import { N30 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const SIDEBAR_PANE_WIDTH = gridSize() * 34;
const resultContainerVerticalPadding = gridSize();

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchDialogWrapper = styled.div<{
	shouldFillContainer?: boolean;
	isExpanded?: boolean;
}>`
	position: relative;
	${(props) => (props.shouldFillContainer ? `width: 100%;` : `width: ${dialogWidth};`)}
	${(props) => (props.isExpanded && props.shouldFillContainer ? `min-width: ${dialogWidth};` : '')}
	box-shadow: ${token(
		'elevation.shadow.overlay',
		'0 ' + grid.multiple(0.5).px + ' ' + grid.px + ' ' + N50A + ', 0 0 1px ' + N60A,
	)};
	border-radius: 3px;
	background-color: ${token('elevation.surface.overlay', '#fff')};
	overflow: hidden;
	margin-top: ${grid.px};
	:focus {
		outline: none;
	}
	display: flex;
	flex-direction: column;
	height: 100%;

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		max-width: 100%;
		border-radius: 0;
		margin-top: 0;
		flex: 1;
		overflow: auto;
	}
`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultContainerOuter = styled.div({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	overflow: 'hidden',
	maxWidth: '100%',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultContainer = styled.div({
	overflowX: 'hidden',
	overflowY: 'auto',
	flex: 1,
	maxWidth: '100%',
	boxSizing: 'border-box',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarScrollContainer = styled.div({
	flex: '1 1 auto',
	overflowX: 'hidden',
	padding: `${token('space.200', '16px')} ${token(
		'space.200',
		'16px',
	)} 0 ${token('space.200', '16px')}`,
});

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarContainerPane = styled.div`
	flex: 0 1 auto;
	width: ${SIDEBAR_PANE_WIDTH}px;
	display: flex;
	flex-direction: column;
	max-height: 100%;
	padding-bottom: ${token('space.200', '16px')};

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		display: none;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarContainerBorder = styled.div({
	margin: `${token('space.200', '16px')} 0`,
	borderLeft: `1px solid ${token('color.border', N30)}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SidebarBottomFixedContainer = styled.div({
	padding: `${token('space.300', '24px')} ${token(
		'space.200',
		'16px',
	)} 0 ${token('space.200', '16px')}`,
	flex: '0 0 auto',
});

export const SidebarContainer = ({ children }: { children: React.ReactNode }) => (
	<>
		<SidebarContainerBorder />
		<SidebarContainerPane>
			<SidebarScrollContainer>{children}</SidebarScrollContainer>
		</SidebarContainerPane>
	</>
);
const totalDialogHeightPx = 776 + resultContainerVerticalPadding * 2;
const footerHeight = gridSize() * 6;

const belowDialogSpace = gridSize() * 28;

const aiFullDialogPromptHeight = 64;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SearchDialogContent = styled.div<{
	minHeight?: number;
	aiSearchFullDialogEnabled?: boolean;
}>`
	display: flex;
	max-height: ${(props) =>
		`calc(100vh - ${
			belowDialogSpace -
			footerHeight +
			(props.aiSearchFullDialogEnabled ? aiFullDialogPromptHeight : 0)
		}px)`};
	box-sizing: border-box;
	flex: 1;
	min-height: ${(props) => (props.minHeight ? `${props.minHeight}px` : 'auto')};

	@media screen and (min-height: ${totalDialogHeightPx + belowDialogSpace}px) {
		max-height: ${(props) =>
			`${
				totalDialogHeightPx -
				footerHeight -
				(props.aiSearchFullDialogEnabled ? aiFullDialogPromptHeight : 0)
			}px;`};
	}

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		max-height: initial;
		overflow: auto;
	}
`;

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ResultRenderWrapper = styled.div<{
	shouldFixMinimumHeight?: number | boolean;
	minimumHeight?: number;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: ${(props) => (props.shouldFixMinimumHeight ? `${props.minimumHeight}px` : 'auto')};

	@media screen and (max-width: ${dialogPageTakeoverWidth}px) {
		flex-grow: 1;
	}
`;
